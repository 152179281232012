import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Navbar from "./sections/Navbar";
import StatusSection from "./sections/StatusSection";
import PastIncidentsSection from "./sections/PastIncidentsSection";
import Footer from "./sections/Footer";

function MainContent() {
  const location = useLocation();
  const showInsights = location.pathname === "/insights";

  return (
    <div className="App">
      <Navbar />
      <div className="main-content">
        <StatusSection showInsights={showInsights} />
        {showInsights && <PastIncidentsSection />}
      </div>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<MainContent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
